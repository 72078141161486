

export default {
    components: {

    },
    data() {
        return {
            // elmentList: [
            //     {
            //         pcElementId:326,
            //         phoneElementId: 338,
            //         descList: [
            //             {
            //                 descName: '七腾机器人雇佣服务',
            //                 sort: 1
            //             }, {
            //                 descName: '以机代人捷“租”先登',
            //                 sort: 2
            //             }, {
            //                 descName: '超低日付即刻开启',
            //                 sort: 3
            //             }],
            //         type: 'image',
            //         sort: 1
            //     },
            //     {
            //         pcElementId:327,
            //         phoneElementId: 339,
            //         descList: [
            //             {
            //                 descName: '超低日付低至千元',
            //                 sort: 1
            //             }, {
            //                 descName: '算法维保一站全包',
            //                 sort: 2
            //             },
            //             {
            //                 descName: '灵活转购随您畅选',
            //                 sort: 3
            //             }, {
            //                 descName: '优享数智全新体验',
            //                 sort: 4
            //             }, {
            //                 descName: '雇佣服务助力轻松实现“机器人换人”',
            //                 sort: 4
            //             }],
            //         type: 'image',
            //         sort: 2
            //     },
            //     {
            //         pcElementId:328,
            //         phoneElementId: 340,
            //         descList: [
            //             {
            //                 descName: '作业条件',
            //                 sort: 1
            //             },
            //             {
            //                 descName: '人工巡检',
            //                 sort: 2
            //             }, {
            //                 descName: '受天气因素影响，恶劣气候环境易产生消极怠工、出人不出力等现象，导致大量人资浪费，甚至引发安全事故',
            //                 sort: 3
            //             }],
            //         type: 'image',
            //         sort: 3
            //     },
            //     {
            //         pcElementId:329,
            //         phoneElementId: 341,
            //         descList: [
            //             {
            //                 descName: '机器人巡检',
            //                 sort: 1
            //             }, {
            //                 descName: ' 不受天气影响，可实现24小时全天候巡检，能够在炎热或寒冷的环境下正常工作',
            //                 sort: 2
            //             }],
            //         type: 'image',
            //         sort: 4
            //     },
            //     {
            //         pcElementId:330,
            //         phoneElementId: 342,
            //         descList: [
            //             {
            //                 descName: '巡检风险性',
            //                 sort: 1
            //             },
            //             {
            //                 descName: '人工巡检',
            //                 sort: 2
            //             }, {
            //                 descName: '危险性高，发生事故将直接危害人身安全',
            //                 sort: 3
            //             }],
            //         type: 'image',
            //         sort: 5
            //     },
            //     {
            //         pcElementId:331,
            //         phoneElementId:343,
            //         descList: [
            //             {
            //                 descName: '机器人巡检',
            //                 sort: 1
            //             }, {
            //                 descName: '风险性低，替代人员巡检，可实现远程操作，保障人身安全',
            //                 sort: 2
            //             }],
            //         type: 'image',
            //         sort:6
            //     },
            //     {
            //         pcElementId:332,
            //         phoneElementId: 344,
            //         descList: [
            //             {
            //                 descName: '巡检效率',
            //                 sort: 1
            //             },
            //             {
            //                 descName: '人工巡检',
            //                 sort: 2
            //             }, {
            //                 descName: '任务繁重，效率低下，准确率受经验、环境等因素影响，人工巡视区域范围受限，漏检率较高',
            //                 sort: 3
            //             }],
            //         type: 'image',
            //         sort:7
            //     },
            //     {
            //         pcElementId:333,
            //         phoneElementId: 345,
            //         descList: [
            //             {
            //                 descName: '机器人巡检',
            //                 sort: 1
            //             }, {
            //                 descName: '数字化运维，高效便捷，配备领先算法，巡检准确率近乎100%',
            //                 sort: 2
            //             }],
            //         type: 'image',
            //         sort:8
            //     },
            //     {
            //         pcElementId:334,
            //         phoneElementId: 346,
            //         descList: [   {
            //             descName: '巡检数据',
            //             sort: 1
            //         }, 
            //             {
            //                 descName: '人工巡检',
            //                 sort: 1
            //             }, {
            //                 descName: '无法实时巡检并采集数据，数据易丢失，无法提供对比数据分析，易延误消除隐患最佳时机',
            //                 sort: 2
            //             }],
            //         type: 'image',
            //         sort:9
            //     },
            //     {
            //         pcElementId:335,
            //         phoneElementId: 347,
            //         descList: [
            //             {
            //                 descName: '机器人巡检',
            //                 sort: 1
            //             }, {
            //                 descName: '提供海量数据采集分析功能，及时将设备状态的检查数据上传到管理中心，便于管理员对系统设备情况进行学控',
            //                 sort: 2
            //             }],
            //         type: 'image',
            //         sort:10
            //     },
            //     {
            //         pcElementId:336,
            //         phoneElementId: 348,
            //         descList: [
            //             {
            //                 descName: '费用支出',
            //                 sort: 1
            //             },
            //             {
            //                 descName: '人工巡检',
            //                 sort:2
            //             }, {
            //                 descName: '单日巡检费用1600元以上，不含保险等费用支出 (数据源自2023年抽样调研102家中大型石化企业数据值估算)',
            //                 sort: 3
            //             }],
            //         type: 'image',
            //         sort:11
            //     },
            //     {
            //         pcElementId:337,
            //         phoneElementId: 349,
            //         descList: [
            //             {
            //                 descName: '机器人巡检',
            //                 sort: 1
            //             }, {
            //                 descName: '单日雇佣费用低至1000元，包含维保、算法升级(根据不同的产品和服务选择，具体费用存在浮动)',
            //                 sort: 2
            //             }],
            //         type: 'image',
            //         sort:12
            //     },
            // ],
            elmentList: [],
        }
    },
    created() {
        // this.$store.dispatch("pageset/getSourceData",
        //     {
        //         createBy: '',
        //         updateBy: '',
        //         elmentList: this.elmentList,
        //         pageId: this.$route.query.pageId,//页面id
        //         pageName: this.$route.path,
        //         path: this.$route.path,
        //         remark: '',
        //     }
        // );
        // 获取当前页面的配置信息
        this.$store.dispatch('pageset/getPageInfo', this.$route.query.pageId).then(() => {
            this.elmentList = this.$store.state.pageset.sourceData.elmentList;
            setTimeout(() => {
            }, 100);
        });
    },
    methods: {
        retention() {
            this.$router.push('/reservation');
        },
        servicePDF() {
            window.open(this.elmentList[16].pcElementId);
        }
    }
}