<template>
  <!-- 雇佣服务 -->
  <div class="employmentPlan">
    <div class="banner" :id="'elm' + elmentList[0].id">
      <div class="img-con">
        <img :src="elmentList[0].pcImg" v-if="$store.state.pageset.pcshow" />
        <img :src="elmentList[0].phoneImg" v-else />
      </div>
      <div class="dec">
        <div class="dec-par" v-animate="'queue-bottom'">
          <p class="p1">{{ elmentList[0].descList[0].descName }}</p>
          <p class="p2" v-if="$store.state.pageset.pcshow">
            <span>{{ elmentList[0].descList[1].descName }}</span
            ><span class="left-item">{{ elmentList[0].descList[2].descName }}</span>
          </p>
          <p class="p2" v-if="!$store.state.pageset.pcshow">
            <span>{{ elmentList[0].descList[1].descName }}</span>
          </p>
          <p class="p2" v-if="!$store.state.pageset.pcshow">
            <span>{{ elmentList[0].descList[2].descName }}</span>
          </p>
        </div>
      </div>
    </div>

    <div class="banner-two" :id="'elm' + elmentList[1].id">
      <div class="img-con">
        <img :src="elmentList[1].pcImg" v-if="$store.state.pageset.pcshow" />
        <img :src="elmentList[1].phoneImg" v-else />
      </div>
      <div class="dec">
        <div class="dec-left" v-animate="'queue-bottom'">
          <p>
            <span>{{ elmentList[1].descList[0].descName }}</span>
            <span class="left-item">{{ elmentList[1].descList[1].descName }}</span>
          </p>
          <p>
            <span>{{ elmentList[1].descList[2].descName }}</span>
            <span class="left-item">{{ elmentList[1].descList[3].descName }}</span>
          </p>
        </div>
        <div class="dec-right" v-animate="'queue-bottom'">
          <p>{{ elmentList[1].descList[4].descName }}</p>
        </div>
      </div>
    </div>

    <!-- 左右排列参数 -->
    <div class="employmentPlan-content">
      <div class="content-tree" v-if="$store.state.pageset.pcshow">
        <div v-for="index in 10" :key="index" :id="'elm' + elmentList[index + 1].id">
          <p class="p-title" v-if="index % 2 === 1">
            {{ elmentList[index + 1].descList[0].descName }}
          </p>
          <div class="fun-item" v-if="index % 2 === 0">
            <div class="fun-left">
              <img :src="elmentList[index + 1].pcImg" />
            </div>
            <div class="fun-right">
              <div class="fun-right-dec" v-animate="'queue-bottom'">
                <p class="p1">
                  {{ elmentList[index + 1].descList[0].descName }}
                </p>
                <p class="p2">
                  {{ elmentList[index + 1].descList[1].descName }}
                </p>
              </div>
            </div>
          </div>
          <div class="fun-item" v-else>
            <div class="fun-left">
              <div class="fun-right-dec" v-animate="'queue-bottom'">
                <p class="p1">
                  {{ elmentList[index + 1].descList[1].descName }}
                </p>
                <p class="p2">
                  {{ elmentList[index + 1].descList[2].descName }}
                </p>
              </div>
            </div>
            <div class="fun-right">
              <img :src="elmentList[index + 1].pcImg" />
            </div>
          </div>
        </div>
      </div>
      <div class="content-tree tree-black" v-if="!$store.state.pageset.pcshow">
        <div v-for="index in 2" :key="index" >
          <p class="p-title" v-if="index % 2 === 1">
            {{ elmentList[index + 1].descList[0].descName }}
          </p>
          <div>
            <img :src="elmentList[index + 1].phoneImg" width="100%" height="100%" />
          </div>
          <div class="fun-dec" v-if="index % 2 === 1">
            <div class="fun-right-dec" v-animate="'queue-bottom'">
              <p class="p1">{{ elmentList[index + 1].descList[1].descName }}</p>
              <p class="p2">{{ elmentList[index + 1].descList[2].descName }}</p>
            </div>
          </div>
          <div class="fun-dec" v-else>
            <div class="fun-right-dec" v-animate="'queue-bottom'">
              <p class="p1">{{ elmentList[index + 1].descList[0].descName }}</p>
              <p class="p2">{{ elmentList[index + 1].descList[1].descName }}</p>
            </div>
          </div>
        </div>
      </div>
      <div class="content-tree" v-if="!$store.state.pageset.pcshow">
        <div v-for="index in 2" :key="index">
          <p class="p-title" v-if="index % 2 === 1">
            {{ elmentList[index + 3].descList[0].descName }}
          </p>
          <div>
            <img :src="elmentList[index + 3].phoneImg" width="100%" height="100%" />
          </div>
          <div class="fun-dec" v-if="index % 2 === 1">
            <div class="fun-right-dec" v-animate="'queue-bottom'">
              <p class="p1">{{ elmentList[index + 3].descList[1].descName }}</p>
              <p class="p2">{{ elmentList[index + 3].descList[2].descName }}</p>
            </div>
          </div>
          <div class="fun-dec" v-else>
            <div class="fun-right-dec" v-animate="'queue-bottom'">
              <p class="p1">{{ elmentList[index + 3].descList[0].descName }}</p>
              <p class="p2">{{ elmentList[index + 3].descList[1].descName }}</p>
            </div>
          </div>
        </div>
      </div>
      <div class="content-tree tree-black" v-if="!$store.state.pageset.pcshow">
        <div v-for="index in 2" :key="index">
          <p class="p-title" v-if="index % 2 === 1">
            {{ elmentList[index + 5].descList[0].descName }}
          </p>
          <div>
            <img :src="elmentList[index + 5].phoneImg" width="100%" height="100%" />
          </div>
          <div class="fun-dec" v-if="index % 2 === 1">
            <div class="fun-right-dec" v-animate="'queue-bottom'">
              <p class="p1">{{ elmentList[index + 5].descList[1].descName }}</p>
              <p class="p2">{{ elmentList[index + 5].descList[2].descName }}</p>
            </div>
          </div>
          <div class="fun-dec" v-else>
            <div class="fun-right-dec" v-animate="'queue-bottom'">
              <p class="p1">{{ elmentList[index + 5].descList[0].descName }}</p>
              <p class="p2">{{ elmentList[index + 5].descList[1].descName }}</p>
            </div>
          </div>
        </div>
      </div>
      <div class="content-tree" v-if="!$store.state.pageset.pcshow">
        <div v-for="index in 2" :key="index">
          <p class="p-title" v-if="index % 2 === 1">
            {{ elmentList[index + 7].descList[0].descName }}
          </p>
          <div>
            <img :src="elmentList[index + 7].phoneImg" width="100%" height="100%" />
          </div>
          <div class="fun-dec" v-if="index % 2 === 1">
            <div class="fun-right-dec" v-animate="'queue-bottom'">
              <p class="p1">{{ elmentList[index + 7].descList[1].descName }}</p>
              <p class="p2">{{ elmentList[index + 7].descList[2].descName }}</p>
            </div>
          </div>
          <div class="fun-dec" v-else>
            <div class="fun-right-dec" v-animate="'queue-bottom'">
              <p class="p1">{{ elmentList[index + 7].descList[0].descName }}</p>
              <p class="p2">{{ elmentList[index + 7].descList[1].descName }}</p>
            </div>
          </div>
        </div>
      </div>
      <div class="content-tree tree-black" v-if="!$store.state.pageset.pcshow">
        <div v-for="index in 2" :key="index">
          <p class="p-title" v-if="index % 2 === 1">
            {{ elmentList[index + 9].descList[0].descName }}
          </p>
          <div>
            <img :src="elmentList[index + 9].phoneImg" width="100%" height="100%" />
          </div>
          <div class="fun-dec" v-if="index % 2 === 1">
            <div class="fun-right-dec" v-animate="'queue-bottom'">
              <p class="p1">{{ elmentList[index + 9].descList[1].descName }}</p>
              <p class="p2">{{ elmentList[index + 9].descList[2].descName }}</p>
            </div>
          </div>
          <div class="fun-dec" v-else>
            <div class="fun-right-dec" v-animate="'queue-bottom'">
              <p class="p1">{{ elmentList[index + 9].descList[0].descName }}</p>
              <p class="p2">{{ elmentList[index + 9].descList[1].descName }}</p>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import employmentPlan from "./employmentPlan.js";

export default employmentPlan;
</script>
<style scoped rel="stylesheet/stylus" lang="scss">
@import "~@/assets/style/common.scss";
@import "employmentPlan.scss";
</style>
